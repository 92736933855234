






























































































import {
  SfHeading,
  SfInput,
  SfButton,
  SfSelect,
  SfCheckbox,
  SfCollectedProduct,
  SfImage,
  SfProperty,
  SfLoader
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, digits } from 'vee-validate/dist/rules';
import {
  ref,
  computed,
  onMounted,
  useRouter,
  useRoute,
  defineComponent,
  useContext,
} from '@nuxtjs/composition-api';

import UserAddressDetails from '~/components/UserAddressDetails.vue';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { useCart } from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useFtDelivery from '~/modules/fortytwo/delivery/composables/useFtDelivery';
import CheckoutDeliveryDate from '~/modules/fortytwo/delivery/components/checkout/V2/CheckoutDeliveryDate.vue';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useUiNotification } from '~/composables';
import GA4Checkout from '~/modules/fortytwo/googleanalytics4/components/GA4Checkout.vue';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useUser } from '~/modules/customer/composables/useUser';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'DeliveryDateStepV2',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    UserAddressDetails,
    DatePicker, 
    CheckoutDeliveryDate, 
    SfCollectedProduct, 
    SfImage,
    SfProperty,
    GA4Checkout,
    SkeletonLoader,
    FtButton,
    SfLoader
  },
  setup() {
    // const deliveryDates = ref([]);
    const groupDeliveryData = ref([]);
    const { cart, load: loadCart, loading: loadingCart } = useCart();
    const { getCheckoutDeliveryGroup, checkoutDeliveryGroup, getDisplayDate, saveQuoteDeliveryInfo, saveQuoteDeliveryInfoResponse, loadingCheckoutDeliveryGroup, loading: loadingSaveDeliveryQuote} = useFtDelivery();
    const router = useRouter();
    const { app } = useContext();
    const cartView = useCartView();
    const error = ref(false);
    const error_message = ref('');
    const route = useRoute();
    const { isAuthenticated } = useUser();
    const haveNoGoZone = ref(false);
    const formRef = ref(null);
    const triggerCheckoutOption = ref(false);

    const {
      send: sendNotification,
    } = useUiNotification();       
    
    // const selectDeliveryDate = (delivery_date, delivery_group_id) => {
    //   const indexOfObject = deliveryDates.value.findIndex(deliveryDate => {
    //     return deliveryDate.delivery_group_id === delivery_group_id;
    //   });
    //   if (indexOfObject != -1){
    //     deliveryDates.value[indexOfObject] = {delivery_date: delivery_date, delivery_group_id: delivery_group_id};
    //   }
    //   else{
    //     deliveryDates.value.push({delivery_date: delivery_date, delivery_group_id: delivery_group_id})
    //   }
      
    // }

    const setGroupDeliveryData = (index, delivery_group_id, delivery_date, timeslot, remarks, requireMattressDisposal) => {
      groupDeliveryData.value[index] = {
        delivery_group_id: delivery_group_id.toString(), 
        delivery_date: delivery_date, 
        timeslot: timeslot, 
        remarks: remarks, 
        require_mattress_disposal: requireMattressDisposal
      };
    }

    const setGroupRemarkData = (index, remarks) => {
      if (groupDeliveryData.value[index]) {
        groupDeliveryData.value[index]['remarks'] = remarks;
      }
    };

    const setGroupRequireMattressDisposalData = (index, requireMattressDisposal) => {
      if (groupDeliveryData.value[index]) {
        groupDeliveryData.value[index]['require_mattress_disposal'] = requireMattressDisposal;
      }
    };
    
    const setDefaultGroupCompleted = () => {
      (checkoutDeliveryGroup as any).value.delivery_groups.forEach((delivery_group, index) => {
        delivery_group.completed = false;
      });
    }
    
    const allGroupCompleted = ref(false);
    const setGroupCompleted = (groupIndex) => {
      (checkoutDeliveryGroup as any).value.delivery_groups[groupIndex].completed = true;

      if (isAllGroupCompleted()){
        allGroupCompleted.value = true;
      }
      else{
        allGroupCompleted.value = false;
      }
    }

    const setGroupNotCompleted = (groupIndex) => {
      (checkoutDeliveryGroup as any).value.delivery_groups[groupIndex].completed = false;

      if (isAllGroupCompleted()){
        allGroupCompleted.value = true;
      }
      else{
        allGroupCompleted.value = false;
      }
      groupDeliveryData.value[groupIndex] = undefined;
    }
    
    const isDeliveryDateDetailsStepCompleted = ref(false);

    const isAllGroupCompleted = () => {
      const indexOfObject = (checkoutDeliveryGroup as any).value?.delivery_groups?.findIndex(delivery_group => {
        return delivery_group.completed === false;
        });
        if (indexOfObject == -1){
          return true;
        }
        else{
          return false;;
        }
        
    }
    
    const handleDeliveryDateSubmit = () => {      
      formRef.value.validate().then(success => {
        if (!success) {
          const errors = formRef.value.errors;
          const errorFields = [];
          for (const [key, value] of Object.entries(errors)) {
            if (value[0]) {
              errorFields.push(key);
            }
          }
          const [first] = errorFields;
          document.querySelector(`.${first}`).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          return;
        }

        saveQuoteDeliveryInfo(groupDeliveryData.value);
        triggerCheckoutOption.value = true;
        router.push(app.localeRoute({ name: 'v2-payment' }));
        isDeliveryDateDetailsStepCompleted.value = true;        
      });
    };

    // const canMoveForward = computed(() => {
    //   if (!loadingCheckoutDeliveryGroup.value){
    //     console.log(checkoutDeliveryGroup);
        
    //     (checkoutDeliveryGroup as any).value?.delivery_groups?.forEach((delivery_group, index) => {
    //     console.log(delivery_group.standard);
        
    //     if (!delivery_group.completed){
    //       return false
    //     }
    //   })
    //   return true;
    //   }
    //   return false;
      
    // });

    onMounted(async () => {
      if (!cart.value.id) {
        await loadCart();
      }

      await Promise.all([getCheckoutDeliveryGroup()]);
      setDefaultGroupCompleted();
      haveNoGoZone.value = (checkoutDeliveryGroup as any).value?.delivery_groups?.filter((deliveryGroup) =>deliveryGroup.nogo == true).length > 0 ? true : false;

      const validStep = await isPreviousStepValid('shipping');
      if (!validStep || !isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'v2-shipping' }));
      }

      
      const error_message_from_validation = computed(() => route.value.query.error ?? '');
      if (error_message_from_validation.value!=''){
        sendNotification({
          id: Symbol('error_delivery_data_invalid'),
          message: app.i18n.t('Please select another delivery date and timeslot. If you continue receiving this error, please refresh this page.') as string,
          type: 'danger',
          icon: 'cross',
          persist: true,
          title: app.i18n.t('Invalid Delivery Date / Timeslot') as string,
        });
      }
    });
    
    // const shipping_postcode = cart.value.shipping_addresses[0].postcode;

    const cartStore = useCartStore();
    const updViewCartSummary = async (viewCartSummary) => {
      await cartStore.setViewCartSummary(viewCartSummary);
    }
    const scrolltoDiv = (divId) => {
      if (!process.client) return;
      const formatDivId = `${divId.toLowerCase().replace(/\s+/g, '')}`;
      const targetDiv = document.getElementById(formatDivId);
      if (!targetDiv) return;
      targetDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }

    return {
      ...cartView,
      handleDeliveryDateSubmit,
      isDeliveryDateDetailsStepCompleted,
      checkoutDeliveryGroup, 
      // selectDeliveryDate,
      setGroupCompleted,
      setGroupNotCompleted,
      allGroupCompleted, 
      setGroupDeliveryData, 
      setGroupRemarkData,
      setGroupRequireMattressDisposalData,
      groupDeliveryData, 
      error, 
      error_message, 
      saveQuoteDeliveryInfoResponse,
      updViewCartSummary,
      scrolltoDiv,
      isAuthenticated,
      loadingCheckoutDeliveryGroup,
      haveNoGoZone,
      cart,
      formRef,
      loadingSaveDeliveryQuote,
      triggerCheckoutOption,
    };
  },
  methods: {
    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },

    // disableDates(date) {
    //   var deliverydates = [{value: '2023-05-20'}, {value: '2023-05-21'}];
    //   const searchDate = deliverydates.filter( deliverydate => deliverydate.value == this.formatDate(date));
    //   return !searchDate.length;
    // },

    searchProduct(products, item_uid){
      // return item_uid;
      const searchProduct = products.filter( product => product.uid == item_uid );
      return searchProduct;
    },
  }
});
