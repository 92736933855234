var render = function (_h,_vm) {var _c=_vm._c;return _c('div',_vm._b({class:[
    _vm.data.class,
    _vm.data.staticClass,
    'sf-textarea',
    {
      'sf-textarea--has-text': !!_vm.props.value,
      'sf-textarea--invalid': !_vm.props.valid,
    } ],style:([_vm.data.style, _vm.data.staticStyle])},'div',_vm.data.attrs,false),[_c('textarea',_vm._g({attrs:{"id":_vm.props.name,"name":_vm.props.name,"placeholder":_vm.props.placeholder,"cols":_vm.props.cols,"rows":_vm.props.rows,"wrap":_vm.props.wrap,"disabled":_vm.props.disabled,"required":_vm.props.required,"maxlength":_vm.props.maxlength,"minlength":_vm.props.minlength,"readonly":_vm.props.readonly},domProps:{"value":_vm.props.value}},_vm.$options.handleInput(_vm.listeners))),_vm._v(" "),_c('label',{staticClass:"sf-textarea__label",attrs:{"for":_vm.props.name}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.props.label))]},null,{ props: _vm.props })],2),_vm._v(" "),(_vm.props.errorMessage)?_c('div',{staticClass:"sf-textarea__error-message"},[_c('transition',{attrs:{"name":"sf-fade"}},[_vm._t("error-message",function(){return [_c('div',{class:{ 'display-none': _vm.props.valid }},[_vm._v("\n          "+_vm._s(_vm.props.errorMessage)+"\n        ")])]},null,{ props: _vm.props })],2)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }