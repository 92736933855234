




















































































































































































































































































































import {
  ref,
  computed,
  onMounted,
  useRouter,
  defineComponent,
  useContext,
  watch, 
  toRef
} from '@nuxtjs/composition-api';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

import {
    SfSelect, 
    SfButton,
    SfTextarea,
    SfCheckbox,
} from '@storefront-ui/vue';
import FtSelect from "~/components/customSFUI/vue/src/components/molecules/FtSelect/FtSelect.vue";
import FtTextarea from "~/components/customSFUI/vue/src/components/atoms/FtTextarea/FtTextarea.vue";
import useFtDelivery from '~/modules/fortytwo/delivery/composables/useFtDelivery';
import CheckoutDeliveryIcon from '~/modules/fortytwo/delivery/components/checkout/V2/CheckoutDeliveryIcon.vue';
import CheckoutDeliveryItem from '~/modules/fortytwo/delivery/components/checkout/V2/CheckoutDeliveryItem.vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { merge } from "lodash-es";
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'CheckoutDeliveryDateV2',
  components: {
    DatePicker, 
    SfSelect, 
    SfButton,
    SfTextarea,
    SfCheckbox,
    FtSelect,
    FtTextarea,
    CheckoutDeliveryIcon,
    CheckoutDeliveryItem,
    ValidationProvider,
    ValidationObserver,
    SkeletonLoader,
  },
  props: ['deliveryGroup', 'index', 'cart'],
  setup(props, {emit}) {
    const { app: { $fc, i18n } } = useContext();
    const websiteDomain = window.location.origin;
    const date = ref();
    const timeslot = ref();
    const remarks = ref('');
    const requireMattressDisposal = ref(false);
    const timeslots = ref();
    const selected_delivery_group_id = ref();
    selected_delivery_group_id.value = props.deliveryGroup.standard.delivery_group_id;
    const selected_plan_type = ref();
    selected_plan_type.value = props.deliveryGroup.standard.plan_type.toLowerCase();
    const delivery_charge_amount = ref();
    delivery_charge_amount.value = {
        standard: props.deliveryGroup.standard.delivery_charge_amount,
        express: props?.deliveryGroup?.express?.delivery_charge_amount
    };
    const total_standard_delivery_charge = computed(()=> delivery_charge_amount.value.standard.charge + delivery_charge_amount.value?.standard?.after_hour_surcharge + delivery_charge_amount.value?.standard?.location_surcharge)
    const total_express_delivery_charge = computed(()=> delivery_charge_amount.value.express.charge + delivery_charge_amount.value?.express?.after_hour_surcharge + delivery_charge_amount.value?.express?.location_surcharge)
    const isCondo = computed(() => props.cart?.shipping_addresses[0]?.building_type.includes('CONDO') ?? false);

    const { timeslots: deliveryDateTimeslots, getDeliveryGroupTimeslotsForDate, delivery_quote, getDeliveryQuote } = useFtDelivery();
    // another watch selected_delivery_group_id, graphql to get delivery_quote delivery date and timeslot
    
    const haveTimeslot = ref(false);
    const standardDeliveryTimeslots = ref([]);
    const expressDeliveryTimeslots = ref([]);

    // return timing object with value of uid, label, is_closed
    const fetchTiming = (deliveryGroup, timeslot) => {
        let timing = {};
        let timeslotLabel = '';
        timing =  merge({}, timing, { uid : timeslot.id });
        timing =  merge({}, timing, { is_closed : timeslot.is_closed });

        timeslotLabel = timeslot.timeslot_start + ' - ' + timeslot.timeslot_end;
        if (timeslot.is_closed) {
            timeslotLabel = timeslotLabel + ' [' + i18n.t('FULL') + ']';
        }
        if (deliveryGroup.after_hours_time_start != '-1' 
        && timeslot.timeslot_start >= deliveryGroup.after_hours_time_start) {
            timeslotLabel = timeslotLabel + ' (+' + $fc(deliveryGroup.after_hours_delivery_charge) + ')';
        }

        timing =  merge({}, timing, { label : timeslotLabel });

        return timing;
    };

    // assign value for standardDeliveryTimeslots array and expressDeliveryTimeslots array
    const fetchDeliveryTimeslotsDropdown = (timeslots, plan_type) => {
        standardDeliveryTimeslots.value = [];
        expressDeliveryTimeslots.value = [];
        if (plan_type == "standard") {
            if (timeslots && timeslots.length > 0) {
                timeslots.forEach((timeslot)=>{
                    standardDeliveryTimeslots.value.push(fetchTiming(props.deliveryGroup.standard, timeslot));
                });
            }
        }

        else if (plan_type == "express") {
            if (timeslots && timeslots.length > 0) {
                timeslots.forEach((timeslot)=>{
                    expressDeliveryTimeslots.value.push(fetchTiming(props.deliveryGroup.express, timeslot));
                });
            }
        }
    };

    watch(
        () => selected_plan_type, 
        async (new_selected_plan_type) => {
            if ( new_selected_plan_type.value == 'postal'){
                // console.log('is_postal!');
                setTimeout(function(){
                    emit('setGroupDeliveryData', props.index, selected_delivery_group_id.value, 0, 0, '', false);
                    emit('groupCompleted');
                }, 1);
                
            }
                     
        },
        {deep: true, immediate: true}
    );
    
    watch(
        () => selected_delivery_group_id, 
        async (new_selected_delivery_group_id) => {
            // get delivery date and timeslot from delivery quote 
            await getDeliveryQuote(new_selected_delivery_group_id.value);
            // set delivery quote delivery date to date property
            if (selected_plan_type.value == "standard"){
                const available_date_temp = props.deliveryGroup.standard.available_dates.filter( available_date => (available_date == delivery_quote.value.delivery_date));
                if (available_date_temp.length != 0){
                    date.value = moment(delivery_quote.value.delivery_date).format("dddd, D MMMM YYYY");
                } else {
                    // if not yet have selected Preferred Delivery Date, show Preferred Delivery Time Dropdown first 
                    haveTimeslot.value = true;
                }
            } else if (selected_plan_type.value == "express") {
                date.value = moment(props.deliveryGroup.express.delivery_date*1000, 'x').format("dddd, D MMMM YYYY");
                // if not yet have selected timeslot value, show Preferred Delivery Time Dropdown first 
                if(!delivery_quote.value.delivery_time_start) {
                    haveTimeslot.value = true;
                }
            } 
            // set delivery quote remark to remark field
            remarks.value = delivery_quote.value.remarks;
            // set delivery quote requireMattressDisposal to requireMattressDisposal field
            requireMattressDisposal.value = props.deliveryGroup.standard.mattress_disposal ? delivery_quote.value.require_mattress_disposal ?? false : false;
        },
        {deep: true, immediate: true}
    );

    watch(
        () => timeslots, 
        async (new_timeslots) => {
            if (new_timeslots.value !== undefined && !timeslot.value){
                const timeslot_temp = timeslots.value.filter( timeslot => (timeslot.timeslot_start == delivery_quote.value.delivery_time_start && timeslot.timeslot_end == delivery_quote.value.delivery_time_end) );
                // console.log(timeslot_temp);
                if (timeslot_temp.length === 0){ // when cannot find timeslot in delivery quote, show timeslot dropdown
                    haveTimeslot.value = true; 
                }
                else if (timeslot_temp.length!=0){
                    // setTimeout(function(){
                        timeslot.value = String(timeslot_temp[0].id);
                        // if have selected Preferred Delivery Date, only show Preferred Delivery Time Dropdown after timeslot have a value
                        haveTimeslot.value = true;
                    // }, 1);
                }
                // change timeslot value to null when previously selected timeslot is closed
                if (timeslot_temp[0].is_closed){
                    timeslot.value = null;
                }
                
            }
            
            
        },
        {deep: true, immediate: true}
    );
    watch(
        () => date,
        async (newDate) => {
            if (newDate.value != null && newDate.value !== undefined){
                // console.log(newDate.value);
                if (selected_plan_type.value == 'express'){
                    await getDeliveryGroupTimeslotsForDate(props.deliveryGroup.express.delivery_group_id, moment(newDate.value, ["dddd, D MMMM YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD"));
                }
                else if (selected_plan_type.value == 'standard'){
                    await getDeliveryGroupTimeslotsForDate(props.deliveryGroup.standard.delivery_group_id, moment(newDate.value, ["dddd, D MMMM YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD"));
                }
                
                if (selected_plan_type.value == 'express' || selected_plan_type.value == 'standard'){
                    fetchDeliveryTimeslotsDropdown(deliveryDateTimeslots.value.timeslots, selected_plan_type.value);
                }
            //   await getDeliveryGroupTimeslotsForDate(props.deliveryGroup.standard.delivery_group_id, newDate.value);
            
                timeslots.value = [];
                timeslots.value = deliveryDateTimeslots.value.timeslots; // #bug at merge ftdelivery.ts need to take note
                // fetchDeliveryTimeslotsDropdown();
            }
            emit('setGroupDeliveryData', props.index, selected_delivery_group_id.value, 
            moment(date.value, ["dddd, D MMMM YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD"), timeslot.value, remarks.value, requireMattressDisposal.value); 
        },
        { deep:true },
    );

    watch (
        () => timeslot, 
        (newTimeslot) => {
            switch (selected_plan_type.value) {
                case 'standard':
                const selected_timeslot = timeslots.value.filter(timeslot_e => (timeslot_e.id == newTimeslot.value));
                if (selected_timeslot.length){
                    if (props.deliveryGroup.standard.after_hours_time_start != -1 && selected_timeslot[0].timeslot_start >= props.deliveryGroup.standard.after_hours_time_start){
                        delivery_charge_amount.value.standard.after_hour_surcharge = props.deliveryGroup.standard.after_hours_delivery_charge;
                        // console.log('timing charge yes!');
                    }
                    else{
                        delivery_charge_amount.value.standard.after_hour_surcharge = 0;
                        // console.log('timing charge no!');
                    }
                }
                
                case 'express':
                    if (date.value!== undefined && newTimeslot.value!== undefined){
                        emit('setGroupDeliveryData', props.index, selected_delivery_group_id.value, 
                        moment(date.value, ["dddd, D MMMM YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD"), newTimeslot.value, remarks.value, requireMattressDisposal.value);
                        emit('groupCompleted');
                    }
                    else{
                        emit('groupNotCompleted');
                    }
                    break;
                default:
                    break;
            }
            // if (selected_plan_type.value == 'standard'){
            //     if (date.value!== undefined && date.value!== undefined){
            //         emit('groupCompleted', props.groupIndex);
            //     }
                
            // }
        },
        { deep: true, }
        
    );

    watch(
        () => remarks,
        async (newRemarks) => {
            if (newRemarks.value != null && newRemarks.value !== undefined) {
                emit('setGroupRemarkData', props.index, newRemarks.value); 
            }
        },
        { deep:true, immediate: true },
    );

    return {
        date,
        timeslot,
        remarks,
        requireMattressDisposal,
        selected_plan_type,
        selected_delivery_group_id,
        timeslots,
        delivery_charge_amount, 
        total_standard_delivery_charge, 
        total_express_delivery_charge,
        haveTimeslot,
        standardDeliveryTimeslots,
        expressDeliveryTimeslots,
        websiteDomain,
        isCondo,
    }
  },
  methods: {
    formatDate(date) {
        return moment(new Date(date), 'x').format("YYYY-MM-DD");
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },

    disableDates(date) {
      const searchDate = this.$props['deliveryGroup'].standard.available_dates.filter( deliverydate => deliverydate == this.formatDate(date));
      return !searchDate.length;
    },
    selectDeliveryDate(delivery_group_id){
        this.$emit('selectDeliveryDate', this.date, delivery_group_id);
    },

    getDisplayDate(timestamp){
        const now = moment().format("YYYY-MM-DD");
        const formatTimestamp = moment(timestamp*1000, 'x').format("YYYY-MM-DD");
        // return formatTimestamp;
        const differentInDay = moment(formatTimestamp).diff(now, 'days'); //calculate different between now and express earliest date
        if (differentInDay === 0){
        return "Today, " + moment(timestamp*1000, 'x').format("D MMMM YYYY");
        }
        if (differentInDay === 1){
        return "Tomorrow, " + moment(timestamp*1000, 'x').format("D MMMM YYYY");
        }
        else{
        return moment(timestamp*1000, 'x').format("dddd, D MMMM YYYY");
        }
    },
    selectDeliveryGroup(delivery_group_id, plan_type){
        if (delivery_group_id != this.selected_delivery_group_id) {
            this.haveTimeslot = false;
            this.selected_delivery_group_id = delivery_group_id;
            this.selected_plan_type = plan_type;
            this.date = undefined;
            this.timeslot = undefined;
            this.remarks = '';
            this.requireMattressDisposal = false;
        }
    },
    isStandardDeliveryDateSelect(){
        return (!this.date || this.date === undefined) ? false : true;
    },

    standardDeliveryPlaceholder(){
        if (this.date === undefined || !this.date){
            return 'Select Preferred Delivery Date first';
        }
        else{
            return 'Select a Timeslot'
        }
    }
  }
});
