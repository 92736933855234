





























import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'FortytwoCheckoutDeliveryIconV2',
    props: {
        deliveryType: {
            type: String,
            default: '',
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        deliveryCharge: {
            type: Number,
            default: 0,
        },
    },
    
})
