





























































import { defineComponent } from '@nuxtjs/composition-api';
import { SfPrice, SfProperty, SfImage } from '@storefront-ui/vue';
import type { CartItemInterface } from "~/modules/GraphQL/types";
import { useCartView } from '~/modules/checkout/composables/useCartView';
import CartCustomOption from '~/modules/fortytwo/customoption/components/CartCustomOption.vue';

export default defineComponent({
    name: 'FortytwoCheckoutDeliveryItemV2', 
    components: {
        SfProperty,
        SfPrice,
        SfImage,
        CartCustomOption,
    },
    props: {
        item: {
        type: Object,
        default: null,
      },
    },
    methods: {
        searchProduct(products, item_uid) {
            const searchProduct = products.filter( product => product.uid == item_uid );
            return searchProduct;
        },
    },
    setup() {
        const cartView = useCartView();
        const getRowTotal = (product: CartItemInterface) =>
        cartView.cartGetters.getItemPrice(product).totalWithTax -
        cartView.cartGetters.getItemPrice(product).special;

        return {
            ...cartView,
            getRowTotal,
        };
    } 
})
